<template>
  <el-form ref="form" :model="form" label-width="120px">
    <el-form-item label="城市">
      <el-input
        v-model="form.city"
        placeholder="输入股票所属地,城市名,例如: 国际庄"
      ></el-input>
    </el-form-item>

    <el-form-item label="人口(万)">
      <el-input
        v-model="form.population"
        type="number"
        min="0"
        max="5000"
        placeholder="输入城市人口(万),例如: 1123"
      ></el-input>
    </el-form-item>

    <el-form-item v-if="canSee" label="操作人">
      <el-input
        v-model="form.chairman"
        placeholder="你不可以添加,别挣扎了"
      ></el-input>
    </el-form-item>

    <el-form-item v-else label="操作人">
      <el-input
        v-model="form.chairman"
        type="password"
        placeholder="你不可以添加,别挣扎了"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="create_population">提交</el-button>
      <router-link to="stock">
        <el-button>取消</el-button>
      </router-link>
    </el-form-item>
  </el-form>
</template>


<script>
import { ElMessage } from "element-plus";
import axios from "axios";
export default {
  data() {
    return {
      form: {
        city: "",
        population: "",
        chairman: "",
      },
      canSee: false,
      population: null,
      loading: true,
      errored: false,
    };
  },
  methods: {
    create_population() {
      const payloads = this.form;
      axios
        .post("/api/population", payloads)
        .then((response) => {
          this.population = response.data;
          console.log("返回:", this.population);
          ElMessage({
            message: "添加城市数据,成功!",
            type: "success",
          });
          this.$router.push({ name: "stock" });
        })
        .catch((error) => {
          ElMessage.error("Oops, 添加城市数据失败.");
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeType() {
      this.canSee = !this.canSee;
    },
  },
};
</script>